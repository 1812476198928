declare var $: any;
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { map,shareReplay } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NbDateService } from "@sb-accelerator/web-ui-sdk";
import { FormGroup, FormControl } from '@angular/forms';
@Injectable({
  providedIn: 'root'
})
export class CommonHelperService {
  private cache$

  domain = environment.URL_NODE;
  responseMessages = {};
  customMessages = {}
  // @BlockUI() blockUI: NgBlockUI;
  menuList: any;
  constructor(private dateService: NbDateService<Date>, private http: HttpClient, private router: Router) {
    // this.prepareCustomJSON().subscribe(data => {
    //   this.customMessages = data;
    // });

  }
  makeRequest(data, url, loader, forceDisable = false): Observable<any> {
    let headers
    
    if (loader) {
      
      headers = new HttpHeaders({ showToast: `${loader}` })
    } else if (forceDisable) {
      headers = new HttpHeaders({ forceDisable: `${forceDisable}` })
    }
    
    return this.http
      .post(this.domain + '/api/PAYMENTS/' + url, data, {headers})
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  // makeRequest(data, url, loader): Observable<any> {
  //   if (loader) {
  //     // this.blockUI.start('Loading...');
  //   }
  //   return this.http.post(this.domain + '/api/PAYMENTS/' + url, data).pipe(map(response => {
  //     // this.blockUI.stop();
  //     return response;
  //   }))
  // }

  // prepareCustomJSON(): Observable<any> {
  //   return this.http.get(`../../assets/i18n/custom/${environment.CLIENT_CODE}-${localStorage.getItem('lang')}.json`);
  // }

  getCustomMessages(key) {
    return this.customMessages[key]
  }

  attachRespMessage(response): any {
    if (this.responseMessages[response.statusCode])
      response.message = this.responseMessages[response.statusCode];
    return response;
  }

  formatDate(date) {
    return this.dateService.format(date, "YYYY-MM-dd");
  }
  validateAllFormFields(formGroup: FormGroup) {
    this.validateFields(formGroup);
    if (!formGroup.valid) {
      let topObj;
      let el = $('.ng-invalid:not(form):first');
      if (el && el.offset()) {
        topObj = { scrollTop: el.offset().top - 150 };
      } else {
        topObj = { scrollTop: 0 };
      }
      $('html,body').animate({ scrollTop: topObj }, 'slow', () => {
        el.focus();
      });
    }
  }
  validateFields(formGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsDirty({ onlySelf: true });
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
  validateAllModalFormFields(formGroup: FormGroup) {
    this.validateFields(formGroup);
    if (!formGroup.valid) {
      let el = $('.ng-invalid:not(form):first');
      $('ngb-modal-window').animate({ scrollTop: (el.offset().top - 150) }, 'slow', () => {
        el.focus();
      });
    }
  }

  validateAllFormFieldsIncludingFomrArray(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      if (control.controls) { // control is a FormGroup
        this.validateAllFormFieldsIncludingFomrArray(control);
      } else { // control is a FormControl
        control.markAsTouched();
      }
    });
  }

  returnPagePermission(pageName): string[] {
    return JSON.parse(localStorage.getItem("permissions"))[pageName]
  }

  interval;
  animateMessage(that, className) {
    // let el = $('.' + className);
    // if (el[0]) {
    //   $('html,body').animate({ scrollTop: (el.offset().top - 150) }, 'slow', () => {
    //     el.focus();
    //     clearInterval(this.interval)
    //     this.interval = setInterval(() => {
    //       that.responseMessage = ""
    //       that.errorMessage = ""
    //       clearInterval(this.interval)
    //     }, 3000);
    //   });
    // } else {
    //   $('html,body').animate({ scrollTop: (50) }, 'slow', () => {
    //     el.focus();
    //     clearInterval(this.interval)
    //     this.interval = setInterval(() => {
    //       that.responseMessage = ""
    //       that.errorMessage = ""
    //       clearInterval(this.interval)
    //     }, 3000);
    //   });
    // }
  }

  animateModalMessage(that, className) {
    let el = $('.' + className);
    $('ngb-modal-window').animate({ scrollTop: (50) }, 'slow', () => {
      el.focus();
      clearInterval(this.interval)
      this.interval = setInterval(() => {
        that.responseMessage = ""
        that.errorMessage = ""
        that.disableButton = false
        clearInterval(this.interval)
      }, 3000);
    });
  }

  clearMessages(that) {
    that.responseMessage = ""
    that.errorMessage = ""
  }

  generateReceipts(data, url, loader): Observable<any> {
    if (loader) {
      // this.blockUI.start('Loading...');
    }
    return this.http.post(this.domain + '/api/PAYMENTS/' + url, data, {
      responseType: 'blob',
      headers: new HttpHeaders().append('Content-Type', 'application/json; charset=utf-8')
    });
  }

  private getErrorMessage = new BehaviorSubject('');
  currentMessage = this.getErrorMessage.asObservable();

  changeMessage(url: string) {
    this.getErrorMessage.next(url)
  }


  private pageMenuTitle = new BehaviorSubject('');
  pageCurrentTitle = this.pageMenuTitle.asObservable();
  private menuArray = new BehaviorSubject([]);
  menuTitleArray = this.menuArray.asObservable();

  showName = new BehaviorSubject('');

  setPageTitle(title) {
    this.pageMenuTitle.next(title);
  }
  setMenuArray(array) {
    this.menuArray.next(array);

  }
  private menuData = new BehaviorSubject([]);
  menuDataList = this.menuData.asObservable();

  setMenuData(data) {
    this.menuData.next(data);
  }


  checkBusinessList(data) {
    
    if (true) {
      this.cache$ = this.getBusinessList(data).pipe(shareReplay(1))
    }
    return this.cache$
  }

  getBusinessList(data) {
    let headers
    headers = new HttpHeaders()
    return this.http.post(this.domain + "/api/PAYMENTS/getBusinessList", data, {
      headers,
    })
  }
  // checkBusinessList(data) {
    
  //   if (!this.cache$) {
  //     this.cache$ = this.getBusinessList(data).pipe(shareReplay(1));
  //   }
  //   return this.cache$;
  // }

  // getBusinessList(data) {
  //   let headers;
  //   headers = new HttpHeaders();
  //   return this.http.post(this.domain + "/api/PAYMENTS/getBusinessList", data, {
  //     headers,
  //   });
  // }
  allowURL = ['/CASHIER/unauthorized', '/CASHIER/dashboard'];
  currentURl(url) {
    this.menuTitleArray.subscribe(res => {
      if (res)
        var caption = res.filter(data => data.relativePath == url)
      if (caption.length) {
        this.pageMenuTitle.next(caption[0].caption)
      } else {
        if (this.allowURL.filter(allowedUrl => allowedUrl == url).length) {
          this.pageMenuTitle.next('')
        }
        else {
          this.router.navigate(['/CASHIER'])
        }
      }

    });

  }

  urlExists(url) {
    return fetch(url, { mode: "no-cors" })
      .then(res => {
        if (res.status == 0)
          return true
        else
          return false
      })
      .catch(err => { return false })
  }

  requestDateFormat(date): string {
    let stringDate: string = "";
    if (date) {
      let stringDay = isNumber(date.day) ? padNumber(date.day) : "";
      let stringMonth = isNumber(date.month) ? padNumber(date.month) : "";
      let stringYear = isNumber(date.year) ? (date.year - 0).toString() : "";
      stringDate = stringYear + '-' + stringMonth + '-' + stringDay;
    }
    return stringDate;

  }
//get menu
  getMenu(){
    const data = {
      token: localStorage.getItem('authToken'),
      userId: localStorage.getItem('userId'),
      appType: "Web_Panel",
      engineCode: "CASHIER",
      languageCode: localStorage.getItem('lang'),
    };

    this.makeRequest(data, 'getUserMenu', true).subscribe(res => {
      if (res.errorCode == 0) {
        this.menuList = res.data.moduleBeanLst;
        let menuData = [];
        menuData.push({relativePath: '/CASHIER/change_password', caption: [this.getCustomMessages("changePwd")]});
        for (let menuList of this.menuList[0].menuBeanList) {
          if (menuList.relativePath) {
            menuData.push({relativePath: '/CASHIER/' + menuList.relativePath, caption: [menuList.caption]});
          }
          if (menuList.childMenuBean) {
            for (let childMenu of menuList.childMenuBean) {
              menuData.push({relativePath: '/CASHIER/' + childMenu.relativePath, caption: [menuList.caption, childMenu.caption]});
            }
          }
        }
        this.setMenuData(this.menuList);
        this.setMenuArray(menuData);
        this.currentURl(this.router.url)
        localStorage.setItem('permissions', JSON.stringify(this.returnPermissions(this.menuList, {})));
      } else if (res.errorCode == 9999) {
        this.changeMessage(res.message);
        this.router.navigate(['/CASHIER/unauthorized'])
      }
      else {
        this.router.navigate(['/CASHIER/unauthorized'])
      }
    });

  }

returnPermissions(menu, permissionObj) {
  for (const obj of menu) {
    if (obj.menuBeanList) {
      for (const data of obj.menuBeanList) {
        if (data.permissionCodeList) {
          permissionObj['' + data.menuCode] = data.permissionCodeList;
        } else if (data.childMenuBean) {

          this.returnPermissions(data.childMenuBean, permissionObj);
        }
      }
    } else {
      this.getPermissions(menu, permissionObj);
    }
  }

  return permissionObj;
}
getPermissions(menu, permissionObj) {
  for (const x of menu) {
    if (x.permissionCodeList) {
      permissionObj['' + x.menuCode] = x.permissionCodeList;
    } else if (x.childMenuBean) {
      this.returnPermissions(x.childMenuBean, permissionObj);
    }
  }
}
}


function padNumber(value: number) {
  if (isNumber(value)) {
    return `0${value}`.slice(-2);
  } else {
    return "";
  }
  
}

function isNumber(value: any): boolean {
  return !isNaN(toInteger(value));
}

function toInteger(value: any): number {
  return parseInt(`${value}`, 10);
}

