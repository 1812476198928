import { AfterViewInit, Component } from "@angular/core";
import {
  NbMenuInternalService,
  NbMenuService,
} from "@sb-accelerator/web-ui-sdk";
import { CommonHelperService } from "../../../services/common-helper.service";

@Component({
  selector: "ngx-one-column-layout",
  styleUrls: ["./one-column.layout.scss"],
  template: `
    <nb-layout>
        <nb-layout-header style="z-index:100"  subheader>
          <ng-content select="nb-menu" ng-mouseleave="hide"></ng-content>
        </nb-layout-header>
      <div style="display:flex; height:200px;"></div>
      <nb-layout-column fixed>
        <nb-card *ngIf="bread">
          <nb-card-body>
            <div class="breadCrumbWrap">
              <div class="containerWrap">
                <div class="breadCrumb">
                  <h1>{{ bread }}</h1>
                </div>
              </div>
            </div>
            <ng-content select="router-outlet"></ng-content>
          </nb-card-body>
        </nb-card>
      </nb-layout-column>
    </nb-layout>
  `,
})
export class OneColumnLayoutComponent implements AfterViewInit{
  pageTitle;
  tada = [];
  bread;
  menuS;
  constructor(
    private commonHelper: CommonHelperService,
    private sidebarService: NbMenuService,
    private service: NbMenuInternalService
  ) {
    this.sidebarService.onItemClick().subscribe((res) => {
      this.bread = res.item.data.breadCrumb;
    });
    this.sidebarService.getSelectedItem().subscribe((res) => {});
    this.commonHelper.pageCurrentTitle.subscribe((res) => {
      this.pageTitle = res;
    });

    this.commonHelper.menuDataList.subscribe((menu) => {
      this.menuS = menu;
    });
    this.sidebarService.onItemHover().subscribe((res) => {
     
      res.item.expanded = true;

      const menus = this.menuS.filter(
        (menu) => menu.data?.tag !== res.item?.data?.tag
      );
      if (res.item) this.service.collapseAll(menus, "");
    });
    // this.sidebarService.onHoverOutItem().subscribe((res) => {
    //   if(!res?.item?.children?.length){
    //     res.item.expanded = false;
    // }
    //     const menus = this.menuS.filter(
    //       (menu) => menu.data?.tag !== res.item?.data?.tag
    //     );
    //     if (res.item) this.service.collapseAll(menus, "");
    //   // }
     
    // });
  }

  ngAfterViewInit() {
    document.getElementById('cashier-menu')?.addEventListener('mouseleave', ()=> {
      this.service.collapseAll(this.menuS, "");
    })
  }

  ngOnInit() {}
  getMenuTitle(title) {
    this.pageTitle = title;
  }
  hide() {
    this.sidebarService.onItemHover().subscribe((res) => {
      res.item.expanded = false;
    });
  }
  setTitle(title) {
    this.commonHelper.setPageTitle(title);
    return false;
  }
}
